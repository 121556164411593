export const initialSearchedProductsState = {
    searchedProducts: {},
};

export const SearchedProductsReducer = (state, action) => {
    switch(action.type) {
        case 'GET_SEARCHED_PRODUCT':
            return {
                ...state,
                searchedProducts: action.payload,
            };
        default:
            return state;
    }
};