export const initialFavoriteState = {
    favorite: [],
    favorites_list: [ ],
};

export const FavoriteReducer = (state, action) => {
    switch(action.type) {
        case 'POST_FAVORITE':
            return {
                ...state,
                favorite: action.payload,
            };
        case 'DELETE_FAVORITE':
            return {
                ...state,
                favorite: action.payload,
            };
        case 'GET_ALL_FAVORITES':
            return {
                ...state,
                favorites_list: action.payload,
            };
        case 'SET_FAVORITES':
            return {
                ...state,
                favorites_list: [],
            };
        default:
            return state;
    }
};