export const initialOrderCheckState = {
    number_order: '',
};

export const OrderCheckReducer = (state, action) => {
    switch(action.type) {
        case 'GET_ORDER_NUMBER':
            return {
                ...state,
                number_order: action.payload,
            };
        default:
            return state;
    }
};