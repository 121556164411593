export const dispatchCat = (data, dispatch) => {
    dispatch({
        type: 'DISPATCH_CAT',
        payload: data
    })
};

export const dispatchProd = (data, dispatch) => {
    dispatch({
        type: 'DISPATCH_PROD',
        payload: data
    })
};

export const dispatchLoading = (data, dispatch) => {
    dispatch({
        type: 'DISPATCH_LOADING',
        loading: data
    })
};

