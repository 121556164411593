import fetch from "isomorphic-unfetch";
import { useCallback, useEffect, useState } from "react";

export const useMediaQuery = (width) => {
    const [targetReached, setTargetReached] = useState(false);
  
    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);
  
    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width - 1}px)`);
      media.addListener(updateTarget);
  
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }
  
      return () => media.removeListener(updateTarget);
    }, []);
  
    return targetReached;
  };

export const sendRequest = (url, method, body = undefined, lang = undefined) => {
    let options = {
        method: method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8"
        }
    };
    if (body) {
        options.body = JSON.stringify(body);
    }
    if (lang) {
        options.headers["Accept-Language"] = lang === "ua" ? "uk" : "ru";
    }
    if (localStorage.token) {
        options.headers = { ...options.headers, Authorization: "Token " + localStorage.getItem("token") };
    }
    return fetch(url, options);
};

export function getOption(label, danger) {
    if (danger) {
        return (
            <div className="status_select">
                <div dangerouslySetInnerHTML={{ __html: label }} />
            </div>
        );
    } else {
        return (
            <div className="status_select">
                <div>{label}</div>
            </div>
        );
    }
}

export const flyingItem = (element, img_width = "160px") => {
    let imgtodrag = element
        ? element.target.closest(".block_for_drag")
        : document.getElementsByClassName("block_for_drag")[0];
    let viewcart = document.getElementsByClassName("basket_info_wrapper")[0];
    let imgtodragImage = imgtodrag.querySelector(".img_to_drag");

    let disLeft = imgtodrag.getBoundingClientRect().left;
    let disTop = imgtodrag.getBoundingClientRect().top;
    let cartleft = viewcart.getBoundingClientRect().left;
    let carttop = viewcart.getBoundingClientRect().top;
    let image = imgtodragImage.cloneNode(true);

    image.style =
        "z-index: 1111; width: " +
        img_width +
        ";opacity:0.8; position:fixed; top:" +
        (disTop + 24) +
        "px;left:" +
        (disLeft + 30) +
        "px;transition: left 1s, top 1s, width 1s, opacity 2s cubic-bezier(1, 1, 1, 1)";
    let rechange = document.body.appendChild(image);
    setTimeout(function() {
        image.style.left = cartleft + "px";
        image.style.top = carttop + "px";
        image.style.width = "40px";
        image.style.opacity = "0";
    }, 200);
    setTimeout(function() {
        rechange.parentNode.removeChild(rechange);
    }, 2000);
};

export const serializeQueryParams = obj => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};

export const prorabFixPrice = price => {
    if (price && price.toString().includes(".")) {
        return Number(price).toFixed(2);
    } else {
        return price;
    }
};

export function AlfaCalculator() {
    const staticInfo = {
        bankRate: {
            "3": 0,
            "5": 2.45,
            "10": 4.77,
            "15": 7,
            /* "18": 8.3,
            "22": 12.55 */
        },
        insurance: {
            "3": 0,
            "5": 2.5,
            "10": 5,
            "15": 7.5,
            /* "18": 9,
            "22": 14.3 */
        },
        months: ["3", "5", "10", "15"/* , "18", "22" */]
    };

    this.info = function(type) {
        switch (type) {
            case "months":
                return `доступное количество месяцов для рассрочки: ${staticInfo[type].join(", ")}`;
                break;
            case "insurance":
            case "bankRate":
                let returnArray = [`${type === "bankRate" ? "Ставка банка:" : "Страховка:"}`];

                for (let key in staticInfo[type]) {
                    returnArray.push(`
                    Количество месяцев: ${key};
                    Процент: ${staticInfo[type][key]};
                    `);
                }
                return returnArray.join("\n");
                break;
            default:
                return `Запрашеваемого Вами типа информации не существует`;
                break;
        }
    };

    this.useCalculate = function(summ, period) {
        let calculateInsurance = (summ / 100) * staticInfo.insurance[period],
            calculateBankRate = (summ / 100) * staticInfo.bankRate[period],
            finalSumm = summ - calculateBankRate + calculateInsurance,
            monthlyPayment = finalSumm / period;

        if (staticInfo.months.some(el => el == period)) {
            return `
                Страховка: ${calculateInsurance};
                Процентная ставка: ${calculateBankRate};
                \n
                Финальная сумма: ${finalSumm};
                Месячный платеж для ${period} месяцев: ${monthlyPayment}; 
            `;
        } else if (summ < 1500 || summ > 75000) {
            return `Извините, но у Вы не можете взять рассрочку на желаемую сумму - ${summ}. Минимальная сумма - 1500 грн., максимальная - 75 000 грн.`;
        } else {
            return `Извините, но у Вас нет возможности взять рассрочку на желаемое количество месяцев - ${period}`;
        }
    };

    this.Calculate = function(summ, period, type) {
        let calculateInsurance = (summ / 100) * staticInfo.insurance[period],
            calculateBankRate = (summ / 100) * staticInfo.bankRate[period],
            finalSumm = summ - calculateBankRate + calculateInsurance,
            monthlyPayment = finalSumm / period;

        if (staticInfo.months.some(el => el == period)) {
            switch (type) {
                case "info":
                    return `
                Страховка: ${calculateInsurance};
                Процентная ставка: ${calculateBankRate};
                \n
                Финальная сумма: ${finalSumm};
                Месячный платеж для ${period} месяцев: ${monthlyPayment}; 
            `;
                    break;
                case "month":
                    return monthlyPayment.toFixed(2);
                    break;
                case "final":
                    return finalSumm.toFixed(2);
                    break;
            }
        } else if (summ < 1500 || summ > 75000) {
            return `Извините, но у Вы не можете взять рассрочку на желаемую сумму - ${summ}. Минимальная сумма - 1500 грн., максимальная - 75 000 грн.`;
        } else {
            return `Извините, но у Вас нет возможности взять рассрочку на желаемое количество месяцев - ${period}`;
        }
    };
}

export const slideHeightFix = () => {
    let collection = [...document.querySelectorAll(".visited_product .slick-active .block_for_drag.visited")],
        biggestHeight = 0;

    if (collection.length > 0) {
        collection.forEach(el => {
            el.querySelector(".more_info").style.minHeight = el.querySelector(".image_block.exception").offsetWidth + "px";
        });
        biggestHeight = Math.max(...collection.map(el => [...el.parentElement.querySelectorAll(".block_for_drag > *:not(.discount):not(.more_info):not(.badges_wrap)"), ...el.parentElement.querySelectorAll(".image_block.exception")].reduce((x, y) => x + y.offsetHeight, 0)));
        collection.forEach(el => {
            el.style.height = `${el.offsetHeight}px`;
            setTimeout(() => {
                el.style.height = `${biggestHeight + 76}px`;
            }, 50);
        });
    }
};

export const productSliderSettings = {
    afterChange: () => slideHeightFix(),
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 860,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3.7,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 630,
            settings: {
                slidesToShow: 3.1,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 380,
            settings: {
                slidesToShow: 1.8,
                slidesToScroll: 1
            }
        }
    ]
};
