import { API_BASE_URL } from "../appState";
import { sendRequest } from "../../components/HelperComponents/functions";

export const postContact = (data, setPurshase_call_status, dispatch) => {
    sendRequest(`${API_BASE_URL}/callback/`, "POST", data)
        .then(response => {
            response.status === 400 ?
                response.json().then(data => {
                    setPurshase_call_status(false);
                    dispatch({
                        type: 'POST_SUBSCRIBE',
                        payload: data
                    });
                })
                : response.json().then(data => {
                    setPurshase_call_status(false);
                    dispatch({
                        type: 'POST_SUBSCRIBE',
                        payload: 'success'
                    });
                })
        })
        .catch(error => console.log(error))
};

export const resetMessage = (text, dispatch) => {
    dispatch({
        type: 'POST_SUBSCRIBE',
        payload: text
    });
};