import { API_BASE_URL } from "../appState";
import { sendRequest } from "../../components/HelperComponents/functions";

export const dispatchPopularProducts = (data, dispatch) => {
    dispatch({
        type: "DISPATCH_POPULAR_PRODUCTS",
        payload: data
    });
};

export const setLoader = (value, dispatch) => {
    dispatch({
        type: "SET_LOADER",
        payload: value
    });
};

export const resendEmail = (email, dispatch) => {
    sendRequest(`${API_BASE_URL}/user-activation/resend-email/`, "POST", { email: email })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: "RESEND_EMAIL",
                payload: data
            });
        })
        .catch(error => console.log(error));
};

export const getCategories = dispatch => {
    sendRequest(`${API_BASE_URL}/category/`, "GET")
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: "GET_CATEGORIES",
                payload: data
            });
        })
        .catch(error => console.log(error));
};

export const dispatchDeskFilters = (data, dispatch) => {
    dispatch({
        type: "DISPATCH_DESK_FILTERS",
        payload: data
    });
};


export const getMain = dispatch => {
    sendRequest(`${API_BASE_URL}/mainpage/`, "GET")
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: "GET_MAIN",
                payload: data
            });
        })
        .catch(error => console.log(error));
};