export const initialCartState = {
    cart: [],
    total_sum: 0,
    total_quantity: 0,
    total_discount: 0
};

export const CartReducer = (state, action) => {
    switch (action.type) {
        case "DISPATCH_CART":
            localStorage.setItem("cart", JSON.stringify(action.payload));
            return {
                ...state,
                cart: action.payload,
                total_sum: (action.payload
                    ? action.payload.reduce(
                          (x, y) => x + (y.item.discount_price ? y.item.discount_price : y.item.price) * y.quantity,
                          0
                      )
                    : 0
                ).toFixed(2),
                total_quantity: action.payload ? action.payload.reduce((x, y) => x + y.quantity, 0) : 0,
                total_discount: (action.payload
                    ? action.payload.reduce(
                          (x, y) =>
                              x +
                              (y.item.discount_price
                                  ? y.item.price * y.quantity - y.item.discount_price * y.quantity
                                  : 0),
                          0
                      )
                    : 0
                ).toFixed(2)
            };
        case "DELETE_CART_ITEM":
            return {
                ...state,
                cart: action.payload,
                total_sum: (action.payload
                    ? action.payload.reduce(
                          (x, y) => x + (y.item.discount_price ? y.item.discount_price : y.item.price) * y.quantity,
                          0
                      )
                    : 0
                ).toFixed(2),
                total_quantity: action.payload ? action.payload.reduce((x, y) => x + y.quantity, 0) : 0,
                total_discount: (action.payload
                    ? action.payload.reduce(
                          (x, y) =>
                              x +
                              (y.item.discount_price
                                  ? y.item.price * y.quantity - y.item.discount_price * y.quantity
                                  : 0),
                          0
                      )
                    : 0
                ).toFixed(2)
            };
        case "POST_ORDER_SUCCESS":
            return {
                ...state,
                cart: [],
                total_sum: 0,
                total_quantity: 0,
                total_discount: 0
            };
        case "CART_CREATION":
            localStorage.setItem("cart_id", action.payload.id);
            return { ...state };
        default:
            return state;
    }
};
