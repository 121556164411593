import { API_BASE_URL } from "../appState";
import { sendRequest } from "../../components/HelperComponents/functions";

export const createCart = (dispatch) => {
    sendRequest(`${API_BASE_URL}/cart/create/`, "POST")
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: 'CART_CREATION',
                payload: data
            });
        })
        .catch(error => console.log(error))
};

export const patchCartStatus = (dispatch, status = 'order') => {
    sendRequest(`${API_BASE_URL}/cart/${localStorage.getItem('cart_id')}/status/`, "PUT", { status })
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: 'PATCH_CART_STATUS',
                payload: data
            });
        })
        .catch(error => console.log(error))
};

export const dispatchCart = (data, dispatch, send = true) => {
    if (send) {
        cartManipulations(data, 'DISPATCH_CART', dispatch)
    }
    else {
        dispatch({
            type: 'DISPATCH_CART',
            payload: data
        })
    }
};

export const deleteCartItem = (id, data, dispatch) => {
    let afterDeletion;
    if (data.length > 1) {
        afterDeletion = data.filter(el => el.id !== id);
        localStorage.setItem('cart', JSON.stringify(afterDeletion))
    } else {
        afterDeletion = [];
        localStorage.removeItem('cart')
    }
    cartManipulations(afterDeletion, 'DELETE_CART_ITEM', dispatch);
};

const cartManipulations = (newCart, type, dispatch) => {
    dispatch({
        type: type,
        payload: newCart
    })
}