import React, { createContext, useReducer } from "react";
import combineReducers from "react-combine-reducers";

import { BaseReducer, initialBaseState } from "./reducers/baseReducer";
import * as baseActions from "./actions/baseActions";

import { CartReducer, initialCartState } from "./reducers/cartRerucer";
import * as cartActions from "./actions/cartActions";

import { CurrentPathReducer, initialCurrentPathState } from "./reducers/currentPathRerucer";
import * as currentPathActions from "./actions/currentPathActions";

import { ContactReducer, initialContactState } from "./reducers/contactReducer";
import * as contactActions from "./actions/contactActions";

import { OrderReducer, initialOrderState } from "./reducers/orderReducer";
import * as orderActions from "./actions/orderActions";

import { SearchedProductsReducer, initialSearchedProductsState } from "./reducers/searchAllReducer";
import * as searchAllActions from "./actions/searchAllActions";

import { FavoriteReducer, initialFavoriteState } from "./reducers/favoriteReducer";
import * as favoriteActions from "./actions/favoriteActions";

import { OrderCheckReducer, initialOrderCheckState } from "./reducers/orderCheckReducer";
import * as orderCheckActions from "./actions/orderCheckActions";

/* export const API_BASE_URL = 'http://api.prorab-new.4-com.pro/v0';
// export const API_BASE_URL = 'https://api.prorab.group/v0';

export const BASE_URL = 'http://prorab-new.4-com.pro';
// export const BASE_URL = 'https://prorab.group'; */
let API_URL = process.env.api,
    URL = process.env.base,
    TYPE = process.env.type;

export const API_BASE_URL = API_URL;
export const CLEAN_API_URL = API_BASE_URL.split('/').slice(0, -1).join('/');
export const SERVER_TYPE = TYPE;
export const BASE_URL = URL;

console.log(API_BASE_URL, BASE_URL);
export const AppContext = createContext({
    ...initialBaseState,
    ...initialCartState,
    ...initialContactState,
    ...initialCurrentPathState,
    ...initialOrderState,
    ...initialSearchedProductsState,
    ...initialFavoriteState,
    ...initialOrderCheckState
});

export const AppProvider = ({ children }) => {
    const [rootReducerCombined, initialStateCombined] = combineReducers({
        BaseReducer: [BaseReducer, initialBaseState],
        CartReducer: [CartReducer, initialCartState],
        ContactReducer: [ContactReducer, initialContactState],
        CurrentPathReducer: [CurrentPathReducer, initialCurrentPathState],
        OrderReducer: [OrderReducer, initialOrderState],
        SearchedProductsReducer: [SearchedProductsReducer, initialSearchedProductsState],
        FavoriteReducer: [FavoriteReducer, initialFavoriteState],
        OrderCheckReducer: [OrderCheckReducer, initialOrderCheckState]
    });

    const [state, dispatch] = useReducer(rootReducerCombined, initialStateCombined);

    const allActions = {
        ...baseActions,
        ...cartActions,
        ...contactActions,
        ...currentPathActions,
        ...orderActions,
        ...searchAllActions,
        ...favoriteActions,
        ...orderCheckActions
    };
    return (
        <AppContext.Provider
            value={{
                ...allActions,

                dispatch: dispatch,

                //BASIC THINGS FOR DASHBOARD, HEADER & SIDEBAR
                popular_products: state.BaseReducer.popular_products,
                loader: state.BaseReducer.loader,
                categoriesForSidebar: state.BaseReducer.categoriesForSidebar,
                cart: state.CartReducer.cart,
                total_sum: state.CartReducer.total_sum,
                total_quantity: state.CartReducer.total_quantity,
                total_discount: state.CartReducer.total_discount,
                show_more: state.BaseReducer.show_more,
                main_page_postion: state.BaseReducer.main_page_postion,
                contact: state.ContactReducer.contact,

                main_title: state.BaseReducer.main_title,

                currentCat: state.CurrentPathReducer.currentCat,
                currentProd: state.CurrentPathReducer.currentProd,
                loading: state.CurrentPathReducer.loading,

                //ORDER RESPONSES
                checkout_order: state.OrderReducer.checkout_order,
                error_checkout: state.OrderReducer.error_checkout,
                cities: state.OrderReducer.cities,
                city: state.OrderReducer.city,
                np_departments: state.OrderReducer.np_departments,

                //SEARCHED PRODUCTS
                searchedProducts: state.SearchedProductsReducer.searchedProducts,

                //FAVORITES
                favorite: state.FavoriteReducer.favorite,
                favorites_list: state.FavoriteReducer.favorites_list,

                //ORDER CHECK
                number_order: state.OrderCheckReducer.number_order,
                actionsTest: { ...allActions },

                //DESKTOP FILTERS
                filteredItems: state.BaseReducer.filteredItems,

                API_URL: API_BASE_URL,
                URL: BASE_URL
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
