
export const initialOrderState = {
    checkout_order: {},
    error_checkout: {},
    cities: [],
    city: {},
    np_departments: []
};

export const OrderReducer = (state, action) => {
    switch (action.type) {
        case "POST_ORDER_SUCCESS":
            localStorage.removeItem("userInfo");
            return {
                ...state,
                checkout_order: {},
                error_checkout: {},
                cities: [],
                city: {},
                np_departments: []
            };
        case "POST_ORDER_FAIL":
            localStorage.removeItem("number_order");
            return {
                ...state,
                error_checkout: action.payload
            };
        case "GET_CITIES":
            return {
                ...state,
                cities: action.payload
            };
        case "GET_CITY":
            return {
                ...state,
                city: action.payload[0],
                cities: []
            };
        case "GET_NP_DEPARTMENT":
            return {
                ...state,
                np_departments: action.payload
            };
        case "USER_TYPED_DATA":
            return {
                ...state,
                checkout_order: action.payload
            };
        default:
            return state;
    }
};
