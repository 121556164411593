import { API_BASE_URL } from "../appState";
import { sendRequest } from "../../components/HelperComponents/functions";

export const postOrder = (data, dispatch) => {
    dispatch({
        type: "POST_ORDER_SUCCESS"
    });
};

export const getCities = (dispatch, query, city) => {
    sendRequest(`${API_BASE_URL}/delivery/cities/?name=${query}`, "GET")
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: `${city ? "GET_CITY" : "GET_CITIES"}`,
                payload: data
            });
            if (city) {
                getNPDepartment(dispatch, data[0].ref);
            } else {
                dispatch({
                    type: "GET_NP_DEPARTMENT",
                    payload: []
                });
            }
        })
        .catch(error => console.log(error));
};

export const getNPDepartment = (dispatch, ref) => {
    sendRequest(`${API_BASE_URL}/delivery/post-office/?ref=${ref}`, "GET")
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: "GET_NP_DEPARTMENT",
                payload: data
            });
        })
        .catch(error => console.log(error));
};

export const userTypedData = (data, dispatch) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
    dispatch({
        type: "USER_TYPED_DATA",
        payload: data
    });
};
