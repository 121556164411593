import { API_BASE_URL } from "../appState";
import { sendRequest } from "../../components/HelperComponents/functions";

export const getOrderNumber = (security_token, dispatch) => {
    sendRequest(`${API_BASE_URL}/user/orders/${security_token}`, "GET")
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: 'GET_ORDER_NUMBER',
                payload: data
            });
        })
        .catch(error => console.log(error))
};