export const initialContactState = {
    contact: ""
};

export const ContactReducer = (state, action) => {
    switch (action.type) {
        case "POST_SUBSCRIBE":
            return {
                ...state,
                contact: action.payload
            };
        default:
            return state;
    }
};
