import { API_BASE_URL } from "../appState";
import { sendRequest } from "../../components/HelperComponents/functions";

export const getSearchedProducts = (query, dispatch) => {
    sendRequest(`${API_BASE_URL}/products/search/${query}/all`, "GET")
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: 'GET_SEARCHED_PRODUCT',
                payload: data
            });
        })
        .catch(error => console.log(error))
};