import { API_BASE_URL } from "../appState";
import { sendRequest } from "../../components/HelperComponents/functions";

export const addFavorite = (data, dispatch) => {
    sendRequest(`${API_BASE_URL}/favorite/add/`, "POST", data)
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: "POST_FAVORITE",
                payload: data
            });
        })
        .catch(error => console.log(error));
};

export const removeFavorite = (id, dispatch) => {
    sendRequest(`${API_BASE_URL}/favorite/add/?product=${id}`, "DELETE")
        .catch(error => console.log(error));
};

export const removeAllFavorites = () => {
    sendRequest(`${API_BASE_URL}/favorite/delete/all/`, "DELETE").catch(error => console.log(error));
};

export const getAllFavorites = dispatch => {
    sendRequest(`${API_BASE_URL}/favorite/`, "GET")
        .then(response => response.json())
        .then(data => {
            dispatch({
                type: "GET_ALL_FAVORITES",
                payload: data
            });
        })
        .catch(error => console.log(error));
};

export const setFavorites = dispatch => {
    dispatch({
        type: "SET_FAVORITES"
    });
};
