export const initialBaseState = {
    popular_products: "",
    main_title: "",
    categoriesForSidebar: {},
    filteredItems: [],
    loader: false,
    show_more: false,
    main_page_postion: 0
};

export const BaseReducer = (state, action) => {
    switch (action.type) {
        //Головна сторінка - Товар тижня - Дивитися ще
        case "TOGLE_SHOW_MORE":
            return {
                ...state,
                show_more: !state.show_more
            };

        case "SAVE_PAGE_POSITION":
            return {
                ...state,
                main_page_postion: action.payload
            };

        case "DISPATCH_POPULAR_PRODUCTS":
            return {
                ...state,
                popular_products: action.payload
            };
        case "GET_CATEGORIES":
            const tempCategoriesForSidebar = {
                id: "root",
                name: "Каталог",
                children: action.payload
            };
            return {
                ...state,
                categoriesForSidebar: tempCategoriesForSidebar
            };
        case "DISPATCH_DESK_FILTERS":
            return {
                ...state,
                filteredItems: action.payload.length > 0 ? action.payload.map(el => el.toString()) : action.payload
            };
        case "SET_LOADER":
            return {
                ...state,
                loader: action.payload
            };
        case "GET_MAIN":
            return {
                ...state,
                main_title: action.payload
            };
        default:
            return state;
    }
};
