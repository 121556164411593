export const initialCurrentPathState = {
    currentCat: "",
    currentProd: "",
    loading: true
};

export const CurrentPathReducer = (state, action) => {
    switch (action.type) {
        case "DISPATCH_CAT":
            return {
                ...state,
                currentCat: action.payload
            };

        case "DISPATCH_PROD":
            return {
                ...state,
                currentProd: action.payload
            };
        case "DISPATCH_LOADING":
            // console.log(action);
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;
    }
};
